const categories = [
  ["honeys", "honeys"],
  ["Pure honeys", "purehoneys"],
  ["Acacia honeys", "acaciahoneys"],
  ["Sunflower honeys", "sunflowerhoneys"],
  ["Linden honeys", "lindenhoneys"],
  ["Buckweat honeys", "buckwheathoneys"],
  ["Multiflorous honeys", "multiflowerhoneys"],
  ["Goldenrod honeys", "goldenrodhoneys"],
  ["Forest honeys", "foresthoneys"],
  ["Raspberry honeys", "raspberryhoneys"],
  ["Coriander honeys", "corianderhoneys"],
  ["Heather honeys", "heatherhoneys"],
  ["Dandelion honeys", "dandelionhoneys"],
  ["Honeydew honey", "honeydewhoney"],
  ["Rapeseed honey", "rapeseedhoney"],
  ["Creamed honeys", "creamedhoneys"],
  ["Honeys with additives", "additives"],
  ["Accessories", "accessories"],
  ["Bee products", "beeproducts"],
  ["Tea", "tea"],
  ["Black tea", "blacktea"],
  ["Green tea", "greentea"],
  ["Fruit tea", "fruittea"],
  ["Functional tea", "functionaltea"],
  ["Rooibos", "rooibos"],
  ["Yerba Mate", "yerbamate"],
  ["Canned tea", "cannedtea"],
  ["Special offer", "specialoffer"],
  ["Gift sets", "giftsets"],
  ["Bio", "bio"],
  ["Confiture", "confiture"],
  ["Soft drinks", "softdrinks"],
  ["Syrup", "syrup"],
];
export default categories;
